window.Components = function (Components) {
    class MediaSocketSecure extends Components.Audioserver.extensions.MediaSocket {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.name = "MediaSocketSecure";
        }

        _getDebugName(host) {
            return "MediaSocketSecure" + this._rndId + "@" + host;
        }

        _getWebSocketHost() {
            var reachMode = CommunicationComponent.getCurrentReachMode(),
                host = this.mediaServer.host,
                mac = this.mediaServer.mac,
                parts;

            if (this.reconnectCnt >= this.MAX_RETRY) {
                return super._getWebSocketHost(...arguments);
            } else {
                // Miniserver Gen. 1
                // • Local:
                //     • App <=> Musicserver Gen. 2
                //         • SSL | Plain
                //             • Use the Musicserver Gen. 2 IP:PORT and MAC for dynDNS URL
                //             • Prefer SSL over Plan, but fallback to Plain
                // • Remote:
                //     • App <=> Miniserver Gen.1 (Proxy) <=> Musicserver Gen. 2
                //         • Plain (The Gen. 1 Miniserver is only capable of Plain traffic)
                //             • Use Miniserver Gen. 2 Connection URL + /proxy/{MUSIC_SERVER_UUID}
                // Miniserver Gen. 2 = wss:// -> Fallback auf ws://
                // • Local:
                //     • App <=> Musicserver Gen. 2
                //         • SSL | Plain
                //             • Use the Musicserver Gen. 2 IP:PORT and MAC for dynDNS URL
                //             • Prefer SSL over Plan, but fallback to Plain
                // • Remote:
                //     • App <=> Miniserver Gen. 2 (Proxy) <=> Musicserver Gen. 2
                //         • SSL | Plain
                //             • Use Miniserver Gen. 2 Connection URL + /proxy/{MUSIC_SERVER_UUID}
                //             • Prefer SSL over Plan, but fallback to Plain
                if (reachMode === ReachMode.LOCAL && this.reconnectCnt === 0) {
                    parts = splitHostAndPort(host);
                    parts.host = parts.host.replace(/[.|:]/g, "-"); // the "." is for IPv4 and the ":" is for IPv6

                    parts.host = parts.host.replace(/[\[\]]/g, ""); // This will only match on IPv6, there is a "[" at the start and a "]" at the end of an IPv6 address
                    //Note: the audioserver has no chinese certificate, hence this wouldn't work locally. Stay with .com (according to Michael this will work)

                    host = sprintf(getDynDnsSslHost("com"), parts.host, mac.replace(/:/g, ""), 7091);
                    this._isP2PConnection = true;
                } else {
                    // Remote
                    host = ActiveMSComponent.getMiniserverConnectionUrl().replace("http://", "").replace("https://", "") + "/proxy/" + this.mediaServer.uuidAction + "/";
                    this._isP2PConnection = false;
                }

                return host;
            }
        }

        getWebsocketProtocol() {
            var wsProtocol = "wss://";

            if (this.reconnectCnt > 2 && this.reconnectCnt % 2 === 0) {
                wsProtocol = super.getWebsocketProtocol(...arguments);
            }

            Debug.Media.Socket && console.log(this.name, "getWebsocketProtocol: " + wsProtocol);
            return wsProtocol;
        }

    }

    if (!("Audioserver" in Components)) {
        Components.Audioserver = {};
    }
    if (!("extensions" in Components.Audioserver)) {
        Components.Audioserver.extensions = {};
    }

    Components.Audioserver.extensions.MediaSocketSecure = MediaSocketSecure;
    return Components;
}(window.Components || {});
